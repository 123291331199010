import { MapItem } from '@capturum/ui/api';

export class ScanPackageRecordEmpty {
  public static readonly type = '[Record empty] Scanned package';

  constructor(public packageItem: MapItem) {}
}

export class AddPackageRecordEmpty {
  public static readonly type = '[Record empty] Add package';

  constructor(public packageItem: MapItem) {}
}

export class ResetPackageRecordEmpty {
  public static readonly type = '[Record empty] Reset record empty package';
}

export class ResetRecordEmpty {
  public static readonly type = '[Record empty] Reset Record Empty';
}
